module.exports = {
  "environment": "canary",
  "url": "https://formwizard.canary.hausgold.de",
  "api": {
    "urls": {
      "wizard": {
        "http": "https://formwizard-api.canary.hausgold.de"
      }
    }
  },
  "analytics": {
    "segmentKey": "gfRQEj3Qjx5LkHDg6P5U86JIrcfKn8HC"
  },
  "deployment": {
    "commit": "3ca1a6018a4110cb3865497f52eebc7233a6245d",
    "branch": "master",
    "deployedAt": "2024-10-07T00:17:16Z",
    "s3Bucket": "formwizard-frontend-canary-master"
  },
  "webpackDevServer": {
    "port": 80,
    "publicPath": "localhost:80"
  },
  "jabber": {
    "userHost": "jabber.canary.hausgold.de",
    "mucHost": "conference.jabber.canary.hausgold.de",
    "wsUrl": "wss://jabber.canary.hausgold.de/ws"
  },
  "appsignal": {
    "key": "90ba6ed0-15d7-4a73-aefa-766d1c83b76c",
    "revision": "3ca1a6018a4110cb3865497f52eebc7233a6245d",
    "branch": "master"
  }
};
